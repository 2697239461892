<template>
  <div v-if="showTitle" class="text-shadow text-center" style=" word-break: break-word;"><span @click="$emit('clickTourTitle')">{{ tour.title||'[未命名]' }}</span> - <span @click="$emit('clickPanoTitle')">{{ pano.title ||'[未命名]'}}</span></div>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        showTitle: false
      }
    },
    inject: {
      publicData: {
        default: {}
      }
    },
    watch: {
      //pano: {
      //  handler: this.checkPano,
      //  deep: true
      //},
      pano(val, oldval) {
        this.show()
      },
    },
    computed: {
      tour() {
        return this.publicData.tour || {}
      },
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
        }
      })
      this.show()
    },
    destroyed() {
    },
    methods: {
      checkPano(val, oldval) {
        console.log(val.guid, old.guid)
      },
      show() {
        this.showTitle = true
      },
      panoTitleafterEnter() {
        setTimeout(() => {
          this.showTitle = false
        }, 2000)
      },
    },
  }
</script>
<style scoped>
  .panoTitle {
    pointer-events: none !important;
    background-image: linear-gradient(to bottom, #0005,#0002, #0000);
    text-shadow: 1px 1px 2px black;
    color: white
  }

    .panoTitle h1 {
      margin: 10px 0;
      font-size: 1.4rem;
    }
</style>
